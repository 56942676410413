// https://github.com/peterbartels/react-use-typewriter/blob/master/src/index.ts
import { useEffect, useRef, useState } from 'react';

export interface State {
  words: string[];
  typeSpeed?: number;
  eraseSpeed?: number;
  afterErasingDelay?: number;
  eraseWords?: boolean;
  afterTypingDelay?: number;
}

const useTypewriter = ({
  words,
  typeSpeed = 100,
  eraseSpeed = 50,
  afterErasingDelay = 500,
  eraseWords = true,
  afterTypingDelay = 1500,
}: State): [string, boolean] => {
  const [loop, setLoop] = useState(0);
  const [index, setIndex] = useState(words[0].length);
  const [word, setWord] = useState(words[0]);
  const [isTyping, setIsTyping] = useState(false);

  const requestRef = useRef<number>(0);
  const previousTimeRef = useRef<number>(0);
  const erasing = useRef<boolean>(false);

  const animate = (time: number) => {
    const deltaTime = time - previousTimeRef.current;
    const speed = erasing.current ? eraseSpeed : typeSpeed;

    if (deltaTime >= speed) {
      const wordIndex = loop % words.length;

      if (index > words[wordIndex].length) {
        time = time + afterTypingDelay;
      }

      if (index === words[wordIndex].length + 1) {
        if (eraseWords) {
          erasing.current = !erasing.current;
        } else {
          setLoop((loop) => loop + 1);
          setIndex(0);
        }
      }

      if (index === -1 && erasing.current) {
        erasing.current = !erasing.current;
        setLoop((loop) => loop + 1);
        time = time + afterErasingDelay;
      }

      setIndex((index) => index + (erasing.current ? -1 : 1));
      setWord(words[wordIndex].substring(0, index));
      setIsTyping(index !== -1 && index < words[wordIndex].length + 1);

      previousTimeRef.current = time;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, [loop, index, word]);

  return [word, isTyping] as [typeof word, typeof isTyping];
};

export default useTypewriter;
