import React from 'react';
import { useScrollYPosition } from 'react-use-scroll-position';

import useTypewriter from '@/hooks/useTypewriter';
import SEO from '@/components/Seo';
import { graphql, useStaticQuery } from 'gatsby';
import LazyLoad from 'react-lazyload';

import bg from './low-poly-grid-haikei.svg';
import jam from './jam.jpg';
import jamVector from './jam-vector.jpg';

const words = [
  `software engineer`,
  `problem solver`,
  `frontend enthusiast`,
  `metalhead`,
];

const isMp4 = (path: string) => path.endsWith(`.mp4`);
const isWebm = (path: string) => path.endsWith(`.webm`);
const isVideo = ({ publicURL }: { publicURL: string }) =>
  isMp4(publicURL) || isWebm(publicURL);

const cx = (...cns: string[]) => cns.filter(Boolean).join(` `);

const Projects: React.FC = () => {
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          frontmatter {
            url
            date
            tags
            title
            backgroundSources {
              publicURL
            }
          }
          html
        }
      }
    }
  `) as { allMarkdownRemark: { nodes: any[] } };

  return (
    <>
      {nodes.map(
        (
          { html, frontmatter: { url, title, date, tags, backgroundSources } },
          index,
        ) => {
          const videoSources = backgroundSources
            .filter(isVideo)
            .map(({ publicURL }) => ({
              src: publicURL,
              // eslint-disable-next-line no-nested-ternary
              type: isWebm(publicURL)
                ? `video/webm`
                : isMp4(publicURL)
                ? `video/mp4`
                : undefined,
            }));
          const imgSource = backgroundSources.filter((src) => !isVideo(src))[0];

          return (
            <div
              className="block mb-16 rounded overflow-hidden bg-white shadow"
              key={title}
            >
              <a
                href={url}
                className="relative group overflow-hidden flex items-end h-80 w-full bg-gray-900"
              >
                <LazyLoad once>
                  {imgSource && (
                    <div
                      className="absolute inset-0 bg-cover"
                      style={
                        imgSource
                          ? { backgroundImage: `url(${imgSource.publicURL})` }
                          : undefined
                      }
                    />
                  )}

                  {videoSources.length > 0 && (
                    <video
                      className="absolute inset-0 object-fill"
                      playsInline
                      autoPlay
                      muted
                      loop
                    >
                      {videoSources.map((sourceProps) => (
                        <source key={sourceProps.src} {...sourceProps} />
                      ))}
                    </video>
                  )}
                </LazyLoad>

                <div className="absolute inset-0 bg-black opacity-25" />
                <div
                  className={cx(
                    `absolute inset-0 bg-gradient-to-tr opacity-80 group-hover:opacity-100 `,
                    index % 3 === 0 ? `from-purple-600` : ``,
                    index % 3 === 1 ? `from-blue-600` : ``,
                    index % 3 === 2 ? `from-yellow-600` : ``,
                  )}
                />
                <div
                  className={cx(
                    `absolute inset-0 bg-gradient-to-bl opacity-0 group-hover:opacity-80 transition-all duration-300`,
                    index % 3 === 0 ? `from-purple-400` : ``,
                    index % 3 === 1 ? `from-blue-400` : ``,
                    index % 3 === 2 ? `from-yellow-400` : ``,
                  )}
                />

                <div className="relative w-full p-4">
                  <small className="block mb-2 text-xs text-white">
                    ({date})
                  </small>
                  <h3 className="font-raleway text-white text-3xl font-bold">
                    {title}
                  </h3>
                  <div className="md:flex justify-between">
                    <ul className="-mb-2">
                      {tags.map((tag) => (
                        <li
                          key={tag}
                          className="mb-2 mr-2 text-white inline-block text-sm"
                        >
                          #{tag}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </a>
              <div
                className="p-4 prose prose-lg mx-auto"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          );
        },
      )}
    </>
  );
};

const IndexPage: React.FC = () => {
  const scrollTop = useScrollYPosition();
  const [currentRole, isTyping] = useTypewriter({ words });

  return (
    <>
      <SEO />

      <div
        className="text-xl overflow-hidden bg-[#1a1a1a] bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <header className="font-raleway w-full min-h-screen text-gray-100 flex items-center justify-center relative">
          <div
            className="container text-center"
            style={{
              transform: `translateY(${Math.min(
                scrollTop * 1.25,
                200,
              )}px) scale(${Math.max(1 - scrollTop / 1000, 0.7)})`,
              opacity: Math.max(1 - scrollTop / 200, 0),
            }}
          >
            <h1 className="mb-6 text-gray-200 uppercase text-5xl font-black">
              Jørn André Myrland
            </h1>
            <h2 className="text-3xl">
              {currentRole}
              <span className={!isTyping ? `animated-cursor` : ``}>|</span>
            </h2>
          </div>

          <div className="absolute bottom-0 mb-24">
            <svg
              className="block fill-current text-white w-6 mx-auto opacity-90"
              style={{
                transform: `scale(${Math.min(
                  1 + scrollTop * 0.2,
                  5000,
                )}) translateY(${Math.min(scrollTop * 0.01, 5)}px)`,
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 70"
              version="1.1"
            >
              <polygon points="0,0 50,70 100,0 " />
            </svg>
          </div>
        </header>

        <main
          className="-mt-96 relative max-w-prose mx-auto min-h-screen pb-24 px-8 "
          style={{
            opacity: Math.min((scrollTop - 150) / 200, 1),
            transform: `scale(${Math.min(0.85 + (scrollTop - 150) / 1000, 1)})`,
          }}
        >
          <LazyLoad height={256}>
            <div className="relative mb-16 w-64 mx-auto group">
              <img
                height={256}
                width={256}
                src={jamVector}
                alt="Jørn André Myrland"
                className="w-full h-auto rounded-full shadow-inner"
              />
              <img
                aria-hidden
                height={256}
                width={256}
                src={jam}
                alt="Jørn André Myrland"
                className="w-full h-auto rounded-full shadow-inner absolute top-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150"
              />
            </div>
          </LazyLoad>

          <div
            className="prose prose-xl"
            style={{ opacity: Math.min((scrollTop - 200) / 200, 1) }}
          >
            <div className="text-center mt-12">
              <a href="mailto:j@myrland.nu" className="underline">
                j@myrland.nu
              </a>
              {` - `}
              <a
                href="https://www.linkedin.com/in/myrland"
                className="underline"
              >
                LinkedIn
              </a>
              {` - `}
              <a href="https://github.com/jmyrland" className="underline">
                GitHub
              </a>
            </div>
          </div>

          <div style={{ opacity: Math.min((scrollTop - 350) / 200, 1) }}>
            <h3 className="mt-16 mb-12 uppercase text-5xl font-black">
              Some things I've made..
            </h3>

            <Projects />
          </div>
        </main>
      </div>
    </>
  );
};

export default IndexPage;
